import axios from 'axios';

const getToken = () => {
    return localStorage.getItem('v2vToken');
};

const api = axios.create({
    baseURL: 'https://value2value.in/api/v1/',
    timeout: 50000,
    headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
    },
    
});

api.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const commonApi = {
    get: (url, params = {}) => {
        return api.get(url, { params });
    },
    post: (url, data = {}) => {
        const headers = {};
        if (data instanceof FormData) {
            headers['Content-Type'] = 'multipart/form-data';
        }
        return api.post(url, data, { headers });
    },
};

export default commonApi;
