import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Sidebar from './components/common/Sidebar';
import { CommonContextProvider } from './context'; // Ensure context is updated
import Header from './components/common/Header';
import City from './components/city';
import State from './components/state';
import Pincode from './components/pincode';
import Tags from './components/tags';
import Sectors from './components/sectors';
import Pricing from './components/pricing';
import Footer from './components/common/Footer';
import AssetsClass from './components/assetsClass';
import News from './components/news';
import Blogs from './components/blogs';
import Setting from './components/setting';
import Popup from './components/popup';
import Alerts from './components/alerts';
import Filemanager from './components/filemanager';
import Tender from './components/tender';

const Login = React.lazy(() => import('./components/common/Login'));
const Signup = React.lazy(() => import('./components/common/Signup'));
const ForgotPassword = React.lazy(() => import('./components/common/ForgotPassword'));
const SiteSetting = React.lazy(() => import('./components/common/SiteSetting'));

function App() {
  let location = useLocation();
  const secretKey = 'v2vKeySec';
  const [user, setUser] = useState(null);

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const storedData = localStorage.getItem('v2vCredentials');
  const decryptedData = storedData ? decryptData(storedData) : null;
  console.log('Decrypted data:', decryptedData);

  // useEffect(() => {
  //   if (decryptedData) {
  //     setUser(decryptedData);
  //   }
  // }, [decryptedData]);

  return (
    <CommonContextProvider>
      <div className="App">
        {!['/login', '/register', '/forgot-password'].includes(location.pathname) && (
          <>
            <Sidebar />
            <Header />
          </>
        )}
        <Suspense fallback={'Loading...'}>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Signup />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/" element={'Home'} />
            <Route exact path="/prof-category" element={<AssetsClass />} />
            <Route exact path="/city" element={<City />} />
            <Route exact path="/state" element={<State />} />
            <Route exact path="/pincode" element={<Pincode />} />
            <Route exact path="/tags" element={<Tags />} />
            <Route exact path="/sectors" element={<Sectors />} />
            <Route exact path="/subscribe" element={<Pricing />} />
            <Route exact path="/tender-news" element={<News />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/site-settings" element={<SiteSetting />} />
            <Route exact path="/profile-settings" element={<Setting />} />
            <Route exact path="/popup" element={<Popup />} />
            <Route exact path="/alerts" element={<Alerts />} />
            <Route exact path="/file-manager" element={<Filemanager />} />
            <Route exact path="/tenders" element={<Tender />} />
          </Routes>
        </Suspense>
        {!['/login', '/register', '/forgot-password'].includes(location.pathname) && (
          <>
            <Footer />
          </>
        )}
      </div>
    </CommonContextProvider>
  );
}

export default App;
