import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Footer.scss'; // Assuming your SCSS file is named Footer.scss
import PartnerLogo from '../../assets/image/partner-1.png'
import GooglePlayImg from '../../assets/image/google-play.png'
import QrImg from '../../assets/image/QR.png'
import StripIcon from '../../assets/image/stripe.webp'
import PaymentIcon from '../../assets/image/payment-methods.webp'
import GlobeIcon from '../../assets/image/globe.png'

const Footer = () => {
  return (
    <footer className="footer">
      <Container className='footer-container'>
        <Row>
          <Col md="12" className="footer-logo">
            <div className="logo">
              <h2>Valuers <span>To</span> Valuers</h2>
              <p>
                V2V & Bids & Procurement Search Engine <br />
                Public and Private Sector & Worldwide Tenders <br />
                Tender Search Engine and Database - Alert Services
              </p>
            </div>
          </Col>
          <Col md="3" className="footer-menu">
            <h5>Menu</h5>
            <ul>
              <li>Home</li>
              <li>About</li>
              <li>V2V</li>
              <li>Online Trade Fair Database</li>
              <li>Free Tenders Publishing Form</li>
              <li>How to Search</li>
            </ul>
          </Col>
          <Col md="3" className="footer-contact">
            <h5>Contract</h5>
            <ul>
              <li>Feedback</li>
            </ul>
          </Col>
          <Col md="3" className="footer-policies">
            <h5>Policies</h5>
            <ul>
              <li>FAQ</li>
              <li>Terms & Conditions</li>
              <li>Privacy Policy</li>
              <li>Shipping Policy</li>
              <li>Refund Policy</li>
              <li>Cookie Policy</li>
              <li>Accessibility Statement</li>
            </ul>
          </Col>
          <Col md="3" className="footer-social">
            <h5>Social</h5>
            <ul>
              <li>LinkedIn</li>
              <li>Facebook</li>
              <li>X</li>
            </ul>
          </Col>
        </Row>
        <div className="footer-bottom">
          <Row>
            <Col md="3" className="footer-qr">
              <div>
                <img src={GooglePlayImg} alt="Google Play" className="play-store" />
              </div>
              <div>
                <img src={QrImg} alt="QR Code" className="qr-code" />
              </div>
            </Col>
            <Col md="3" className="footer-location">
              <h5>Location</h5>
              <p>
                Wyoming, WY 82601 USA <br />
                +1-307-439-0046 <br />
                contact@v2v.com
              </p>
            </Col>
            <Col md="3" className="footer-partnerships">
              <h5>Partnerships</h5>
              <ul>
                <li>Partnerships</li>
                <li>Sponsorships</li>
                <li>Byrdhouseapp</li>
              </ul>
            </Col>
            <Col md="3">
              <div className="partners-logos">
                <img src={PartnerLogo} alt="Partner 1" />
                <img src={PartnerLogo} alt="Partner 1" />
                <img src={PartnerLogo} alt="Partner 1" />
                <img src={PartnerLogo} alt="Partner 1" />
                <img src={PartnerLogo} alt="Partner 1" />
                <img src={PartnerLogo} alt="Partner 1" />
              </div>
            </Col>
            <Col md="12">
              <div className="footer-legal">
                <img src={GlobeIcon} alt="Partner 1" className='globe-icon' />
                <div className="footer-company">
                  <h5>Valuers to Valuers and Bids LLC.</h5>
                  <p>
                    © 2016-2024 by Valuers to Valuers and Bids LLC. Made with V2V | All rights reserved
                  </p>
                  <p><strong>D-U-N-S Number : 126768052</strong></p>
                </div>
              </div>
            </Col>
            <Col md="12" className="footer-security">
              <img src={StripIcon} alt="Powered by Stripe" className="payment-logos" />
              <img src={PaymentIcon} alt="Payment Methods" className="payment-logos" />
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
