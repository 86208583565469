import React, { useState, useContext } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, Collapse } from 'reactstrap';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/sidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faMapLocation, faLocation, faNewspaper, faBlog, faLayerGroup, faMonument, faRotate, faCircle, faDotCircle, faChevronDown, faUsers, faUser, faTowerBroadcast, faChartArea, faClapperboard, faBookReader, faChevronUp, faTag, faSection, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { faMeetup } from '@fortawesome/free-brands-svg-icons';
import { CommonContext } from '../../context';

const Sidebar = () => {
    const { isHovered, setIsHovered, isSidebarOpen, setIsSidebarOpen, } = useContext(CommonContext);

    const [openSection, setOpenSection] = useState(null);
    const urlPath = useLocation();

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const toggleSection = (section) => {
        setOpenSection(prevState => (prevState === section ? null : section));
    };

    return (
        <div
            className={`bg-menu-theme custom-scrollbar scrollable ${isSidebarOpen || isHovered ? '' : 'collapsed'}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Navbar color="dark" light className="menu-vertical" style={{ flexDirection: 'column' }}>
                <div className='top'>
                    <NavbarBrand href="/" className="app-brand-link">
                        <span className="app-brand-logo demo">
                            <img src={'https://placehold.co/60x40/FFFFFF/000000.png'} className='img-fluid' alt="Logo" />
                        </span>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleSidebar} className="ms-auto d-none">
                        <FontAwesomeIcon
                            icon={isSidebarOpen ? faDotCircle : faCircle}
                            className="menu-toggle-icon d-none"
                        />
                    </NavbarToggler>
                </div>
                <Nav navbar className="menu-inner-shadow flex-column w-100">

                    <NavItem className={`menu-item ${urlPath.pathname === '/users' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('users')}>
                            <div className="menu-parent">
                                <FontAwesomeIcon icon={faUsers} className="menu-icon" />
                                <div>Users</div>
                            </div>
                            <FontAwesomeIcon
                                icon={openSection === 'users' ? faChevronUp : faChevronDown}
                                className="menu-toggle-icon"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'users'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/sub-admin' ? 'active' : ''}`} >
                                    <Link to="" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faChartArea} className="menu-icon" />
                                        <div>Sub Admin</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/valuers' ? 'active' : ''}`} >
                                    <Link to="" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faClapperboard} className="menu-icon" />
                                        <div>Valuers</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/contractors' ? 'active' : ''}`} >
                                    <Link to="" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMeetup} className="menu-icon" />
                                        <div>Organations</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/contractors' ? 'active' : ''}`} >
                                    <Link to="" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faUser} className="menu-icon" />
                                        <div>Contractors</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/e-library' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('e-library')}>
                            <div className="menu-parent">
                                <FontAwesomeIcon icon={faBookReader} className="menu-icon" />
                                <div>News</div>
                            </div>
                            <FontAwesomeIcon
                                icon={openSection === 'e-library' ? faChevronUp : faChevronDown}
                                className="menu-toggle-icon"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'e-library'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/tender-news' ? 'active' : ''}`}>
                                    <Link to="/tender-news" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faNewspaper} className="menu-icon" />
                                        <div>Tender News</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/blogs' ? 'active' : ''}`}>
                                    <Link to="/blogs" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faBlog} className="menu-icon" />
                                        <div>Blogs</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item sub ${urlPath.pathname === '/master' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('master')}>
                            <div className="menu-parent">
                                <FontAwesomeIcon icon={faLayerGroup} className="menu-icon" />
                                <div>Master</div>
                            </div>
                            <FontAwesomeIcon
                                icon={openSection === 'master' ? faChevronUp : faChevronDown}
                                className="menu-toggle-icon"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'master'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/prof-category' ? 'active' : ''}`}>
                                    <Link to="/prof-category" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faStickyNote} className="menu-icon" />
                                        <div>Professional Category</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/city' ? 'active' : ''}`}>
                                    <Link to="/city" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMapPin} className="menu-icon" />
                                        <div>City</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/state' ? 'active' : ''}`}>
                                    <Link to="/state" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMapLocation} className="menu-icon" />
                                        <div>State</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/pincode' ? 'active' : ''}`}>
                                    <Link to="/pincode" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faLocation} className="menu-icon" />
                                        <div>Pincode</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/tags' ? 'active' : ''}`}>
                                    <Link to="/tags" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faTag} className="menu-icon" />
                                        <div>Tags</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/sectors' ? 'active' : ''}`}>
                                    <Link to="/sectors" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faSection} className="menu-icon" />
                                        <div>Sectors</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/event' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('event')}>
                            <div className="menu-parent">
                                <FontAwesomeIcon icon={faMonument} className="menu-icon" />
                                <div>Opportunities</div>
                            </div>
                            <FontAwesomeIcon
                                icon={openSection === 'event' ? faChevronUp : faChevronDown}
                                className="menu-toggle-icon"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'event'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/eventtransaction' ? 'active' : ''}`} >
                                    <NavItem className={`menu-item ${urlPath.pathname === '/tenders' ? 'active' : ''}`} >
                                        <Link to="tenders" className="menu-link menu-toggle">
                                            <FontAwesomeIcon icon={faTowerBroadcast} className="menu-icon" />
                                            <div>Tenders</div>
                                        </Link>
                                    </NavItem>
                                    <Link to="" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faRotate} className="menu-icon" />
                                        <div>Contracts</div>
                                    </Link>
                                    <Link to="" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faRotate} className="menu-icon" />
                                        <div>Projects</div>
                                    </Link>
                                    <Link to="" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faRotate} className="menu-icon" />
                                        <div>Carrier</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                </Nav>
            </Navbar>
        </div>
    );
};

export default Sidebar;
