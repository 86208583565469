import React, { useContext, useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faStar, faEye } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { CommonContext } from '../../context';
import '../../assets/css/header.scss';

const Header = () => {
  const { user } = useContext(CommonContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <Navbar color="light" light expand="md" className="tender-navbar">
      <NavbarBrand href="/" className="mr-auto">Welcome to Valuers To Valuers</NavbarBrand>
      <Nav className="ml-auto header-btn" navbar>
        <NavItem>
          <div className='join-btn'>
            <Button color="light" className="nav-btn">
              <FontAwesomeIcon icon={faBell} /> Add Alert
            </Button>
            <Button color="light" className="nav-btn">
              <FontAwesomeIcon icon={faBell} className="icon-pink" /> My Alerts
            </Button>
          </div>
        </NavItem>
        <NavItem>
          <Button color="light" className="nav-btn fav-btn">
            <FontAwesomeIcon icon={faStar} /> My Favorites
          </Button>
        </NavItem>
        <NavItem style={{ display: 'none' }}>
          <Link to="/subscribe" className='subscribe-btn btn btn-warning'>Subscribe</Link>
        </NavItem>
        <NavItem>
          <Button color="light" className="nav-btn free-btn">
            <FontAwesomeIcon icon={faEye} /> Free Tender View
            <span className="badge">49</span>
          </Button>
        </NavItem>
        <NavItem>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="profile-dropdown">
            <DropdownToggle color="light" className="nav-btn profile-btn">
              <FontAwesomeIcon icon={faUser} /> {user?.name || 'User'}
            </DropdownToggle>
            <DropdownMenu right className="profile-menu">
              <DropdownItem>
                <Link to="/site-settings">Site Settings</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/profile-settings">Profile Settings</Link>
              </DropdownItem>
              <DropdownItem style={{ display: 'none' }}>
                <Link to="/subscribe">Subscribe</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/logout" onClick={() => localStorage.clear()}>Log Out</Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
